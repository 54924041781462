// Protected.js
import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function Protected(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const { Page, access } = props;

useEffect(() => {
    const checkAccess = () => {
        let login = localStorage.getItem("login");
        let currentPath = window.location.pathname;
        
        if (!login) {
            // Save the full current URL (path + search params)
            localStorage.setItem("redirectAfterLogin", location.pathname + location.search);
            // Use a different key for login status
            localStorage.setItem("tempLoginStatus", "Please login to view this page!");
            navigate("/", { replace: true });
        } else if (currentPath === "/dashboard" && access === undefined) {
            navigate("/dashboard", { replace: true });
        }  else if (currentPath !== "/dashboard" && access) {
            navigate(location.pathname + location.search);
        } 
        // else if (currentPath !== "/dashboard" && !access) {
        //     localStorage.setItem("tempLoginStatus", "You do not have access to this page!");
        //     navigate("/unauthorized", { replace: true });
        // }
    };

    checkAccess();
}, [navigate, access]);

    return <Page />;
}